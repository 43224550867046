import React from 'react';
import { useTranslation } from 'react-i18next';
import success from './illustration-success.svg';
import './Modals.scss';

const SuccessPage = () => {
  const { t } = useTranslation();
  return (
    <div className="modal__text">
      <h3>{t('cart.confirmed')}</h3>
      <div>
        <img alt={t('cart.confirmed')} className="confirmcheckout__illustration" src={success} />
        <p className="modal__text">{t('cart.confirmed_text')}</p>
      </div>
    </div>
  );
};

export { SuccessPage };
