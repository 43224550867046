import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './Header.scss';

const Header = () => {
  return (
    <div className="header">
      <div className="header__logo">
        <Logo />
      </div>
      <div className="header__spacer"></div>
    </div>
  );
};

export { Header };
