import React from 'react';
import { useTranslation } from 'react-i18next';
import './ClearButton.scss';
interface ClearButtonProps {
  clearHandler: Function;
}

const ClearButton = (props: ClearButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="clearbutton" onClick={() => props.clearHandler()}>
      {t('cart.clear')}
    </div>
  );
};

export { ClearButton };
