import { injectable, inject } from 'inversify';
import * as configuration from '../configuration';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IInsightsService } from './iinsights-service';

@injectable()
export class AppInsightsService implements IInsightsService {
  @inject(configuration.IConfigurationServiceType) private _configuration: configuration.IConfigurationService;
  private _appInsights: ApplicationInsights;

  load(): void {
    const { applicationInsightsKey } = this._configuration.get();
    if (applicationInsightsKey) {
      this._appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: applicationInsightsKey,
          isCookieUseDisabled: true,
        },
      });
      this._appInsights.loadAppInsights();
      this.trackPageView();
    }
  }

  trackPageView(): void {
    // eslint-disable-next-line no-unused-expressions
    this._appInsights?.trackPageView({ uri: `${window.location.origin}/${window.location.pathname}` });
  }
}
