import { useState, useEffect } from 'react';
import { useInjection } from '../../../../../core/ioc';
import { IHttpFactoryType, IHttpFactory } from '../../../../../core/http';
import { Customer } from '../../../../models';
import {
  IConfigurationServiceType,
  IConfigurationService,
} from '../../../../../core/configuration';
import {
  IAuthenticationServiceType,
  IAuthenticationService,
} from '../../../../../core/authentication';

type MyCustomersEndpointResult = Customer[];

type hookResult = [MyCustomersEndpointResult, boolean];

export function useOrganizationCustomers(): hookResult {
  const configurationService = useInjection<IConfigurationService>(
    IConfigurationServiceType
  );
  const config = configurationService.get();
  const [result, setResult] = useState<hookResult>([
    [],
    true,
  ]);

  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const authenticationService = useInjection<IAuthenticationService>(
    IAuthenticationServiceType
  );

  useEffect(() => {
    (async () => {
      const http = httpFactory.build();
      const response = await http.get<MyCustomersEndpointResult>(
        `${config.organizationsApiEndpoint}/v1/myorganization/mycustomers`
      );
      setResult([response, false]);
    })();
  }, [httpFactory, config.organizationsApiEndpoint, authenticationService]);

  return result;
}
