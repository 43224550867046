import React from 'react';

const CartSearchBar = (/*props: CartSearchBarProps*/) => {
  return (
    <div>
      {/* <input className="cartsearchbar" type="text" placeholder="search" /> */}
    </div>
  );
};

// interface CartSearchBarProps {}

export { CartSearchBar };