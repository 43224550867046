import React from 'react';
import { useTranslation } from 'react-i18next';
import { Totals } from '../../models';
import { moneyFormatter } from '../../utils';
import './SummaryTotals.scss';

interface SummaryTotalsProps {
  totals?: Totals;
}

const SummaryTotals = (props: SummaryTotalsProps) => {
  const { t } = useTranslation();
  //const { netTotal, taxTotal, grossTotal, currency, totalItems, totalProducts } = props.totals == null ? {} : props.totals;
  return (
    <div className="summarytotals">
      {props.totals?.currency === null ? (
        <></>
      ) : (
        <div className="summarytotals__body">
          <div className="summarytotals__section summarytotals__products">
            <div className="summarytotals__label">{t('summary.labels.products_label')}</div>
            <div className="summarytotals__value">{props.totals?.haworthPositions}</div>
          </div>
          <div className="summarytotals__section summarytotals__items">
            <div className="summarytotals__label">{t('summary.labels.items')}</div>
            <div className="summarytotals__value">{props.totals?.haworthItems}</div>
          </div>
          <div className="summarytotals__section summarytotals__net">
            <div className="summarytotals__label">{t('summary.labels.total_net_sum')}</div>
            <div className="summarytotals__value">
              {props.totals?.originalNetTotal &&
                moneyFormatter({ amount: props.totals?.originalNetTotal, currency: props.totals?.currency })}
            </div>
          </div>
          {!!props.totals?.discountTotal && (
            <div className="summarytotals__section summarytotals__discount">
              <div className="summarytotals__label">{t('summary.labels.discount')}</div>
              <div className="summarytotals__value">
                {props.totals?.discountTotal &&
                  `-${moneyFormatter({ amount: props.totals?.discountTotal, currency: props.totals?.currency })}`}
              </div>
            </div>
          )}
          {!!props.totals?.freightTotal && (
            <div className="summarytotals__section summarytotals__freight summarytotals__red">
              <div className="summarytotals__label">{t('summary.labels.freight')}</div>
              <div className="summarytotals__value">
                {props.totals?.freightTotal &&
                  moneyFormatter({ amount: props.totals?.freightTotal, currency: props.totals?.currency })}
              </div>
            </div>
          )}
          {!!props.totals?.assemblyTotal && (
            <div className="summarytotals__section summarytotals__assembly summarytotals__red">
              <div className="summarytotals__label">{t('summary.labels.assembly')}</div>
              <div className="summarytotals__value">
                {props.totals?.assemblyTotal &&
                  moneyFormatter({ amount: props.totals?.assemblyTotal, currency: props.totals?.currency })}
              </div>
            </div>
          )}
          {!!props.totals?.ecoTaxTotal && (
            <div className="summarytotals__section summarytotals__assembly">
              <div className="summarytotals__label">{t('summary.labels.ecotax')}</div>
              <div className="summarytotals__value">
                {props.totals?.ecoTaxTotal &&
                  moneyFormatter({ amount: props.totals?.ecoTaxTotal, currency: props.totals?.currency })}
              </div>
            </div>
          )}
          <div className="summarytotals__section">
            <div className="summarytotals__label">{t('summary.labels.net')}</div>
            <div className="summarytotals__totalvalue summarytotals__gross">
              {props.totals?.grandTotal &&
                moneyFormatter({ amount: props.totals.grandTotal, currency: props.totals?.currency })}
            </div>
          </div>
          {props.totals?.dealerPositions !== 0 && (
            <>
              <div className="summarytotals__section summarytotals__products">
                <div className="summarytotals__label">{t('summary.labels.products_dealer_label')}</div>
                <div className="summarytotals__value">
                  {props.totals?.dealerPositions} {t('summary.labels.products')}
                </div>
              </div>
              <div className="summarytotals__section summarytotals__items">
                <div className="summarytotals__label">{t('summary.labels.dealer_items')}</div>
                <div className="summarytotals__value">
                  {props.totals?.dealerItems} {t('summary.labels.units')}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { SummaryTotals };
