import React from 'react';
import i18n from 'i18next';
import { useEffect } from 'react';

interface ServiceBarProps {
  clientId: string;
  scope: string;
  settingsUri: string;
}

export function ServiceBar({ clientId, scope, settingsUri }: ServiceBarProps): React.JSX.Element {
  useEffect(() => {
    customElements.whenDefined('service-bar').then(() => {
      // eslint-disable-next-line no-undef
      const todoListElement = document.querySelector('service-bar') as HTMLServiceBarElement;
      todoListElement.addEventListener('languageSet', (event: any) => {
        i18n.changeLanguage(event.detail);
      });
    });
  }, []);
  return (
    <service-bar
      languages-json={JSON.stringify(i18n.languages)}
      selected-language={i18n.language}
      style={{ fontSize: '16px' }}
      client-id={clientId}
      scope={scope}
      settings-uri={settingsUri}
      shopping-cart-button-enabled={true}/>
  );
}