const selectTheme = (actualTheme: any) => ({
  ...actualTheme,
  colors: {
    ...actualTheme.colors,
    primary25: 'rgba(55,59,81,.25)',
    primary: '#373b51',
  },
});

const selectStyles = {
  control: (actual: any) => ({
    ...actual,
    borderRadius: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #373b51',
    boxShadow: 0,
    fontWeight: 300,
  }),
  indicatorSeparator: (actual: any) => ({
    ...actual,
    width: 0,
  }),
};

export { selectStyles, selectTheme };
