import { useState, useEffect } from 'react';

export interface useDebounceResult<T> {
  debouncedValue: T;
}

export function useDebounce<T>(value: T, delay: number): useDebounceResult<T> {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Clear Timeout if value | delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return { debouncedValue };
}