import React from 'react';
import editIcon from './edit-item-icon.svg';
import { useTranslation } from 'react-i18next';
import './ProductButton.scss';
import { useInjection } from 'core/ioc';
import { ConfigurationService, IConfigurationServiceType } from 'core/configuration';
interface ProductConfigureButtonProps {
  itemId: string;
  useLegacyConfiguratorApp: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductConfigureButton = (props: ProductConfigureButtonProps) => {
  const { t } = useTranslation();
  const configuration = useInjection<ConfigurationService>(IConfigurationServiceType);
  const configuratorBaseUrl = props.useLegacyConfiguratorApp ? configuration.get().configuratorLegacyBaseUrl : configuration.get().configuratorCatalogAppBaseUrl;

  const configure = () => {
    window.location.assign(`${configuratorBaseUrl}?itemId=${props.itemId}`);
  };

  return (
    <button
      className="productbutton productbutton__configure"
      onClick={() => configure()}
      title={t('product.buttons.configure')}
    >
      <img alt={t('product.buttons.configure')} className="productbutton__image" src={editIcon} />
    </button>
  );
};

export { ProductConfigureButton };
