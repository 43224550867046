import React from 'react';
import deleteIcon from './delete-icon.svg';
import { useTranslation } from 'react-i18next';
import './ProductButton.scss';

interface ProductDeleteButtonProps {
  deleteProduct: Function;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductDeleteButton = (props: ProductDeleteButtonProps) => {
  const { t } = useTranslation();
  return (
    <button
      className="productbutton productbutton__delete"
      title={t('product.buttons.delete')}
      onClick={() => props.deleteProduct()}
    >
      <img alt={t('product.buttons.delete')} className="productbutton__image" src={deleteIcon} />
    </button>
  );
};

export { ProductDeleteButton };
