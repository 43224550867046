import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IConfigurationServiceType, ConfigurationService } from 'core/configuration';
import { useInjection } from 'core/ioc';
import './ConfigureNewProductButton.scss';

import previousIcon from './previous-icon.svg';
import { PunchoutDataContext } from 'core/PunchoutDataContext';
import { PunchoutOperation } from 'core/punchout';
interface ConfigureNewProductButtonProps {
  useLegacyConfiguratorApp: boolean;
}
const ConfigureNewProductButton = (props: ConfigureNewProductButtonProps) => {
  const configuration = useInjection<ConfigurationService>(IConfigurationServiceType);
  const configuratorBaseUrl = props.useLegacyConfiguratorApp ? configuration.get().configuratorLegacyBaseUrl : configuration.get().configuratorCatalogAppBaseUrl;

  const { t } = useTranslation();
  const punchoutContext = useContext(PunchoutDataContext);
  const shouldBeRendered = punchoutContext?.punchOutOperationType !== PunchoutOperation.Inspect;

  const configure = () => {
    window.location.assign(configuratorBaseUrl);
  };

  return (
    shouldBeRendered &&
    <div className='configurenewproduct' onClick={configure}>
      <img alt={t('cart.configure_new_product')} className="configurenewproduct__image" src={previousIcon} />
      <span className="configurenewproduct__label">{t('cart.configure_new_product')}</span>
    </div>
  );
};

export { ConfigureNewProductButton };
