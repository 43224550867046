import { injectable, inject } from 'inversify';
import * as authentication from '../authentication';
import * as configuration from '../configuration';
import { ISignalRService } from './isignalr.service';
import * as signalR from '@aspnet/signalr';

@injectable()
export class SignalRService implements ISignalRService {
  @inject(authentication.IAuthenticationServiceType) private authenticationService: authentication.IAuthenticationService;
  @inject(configuration.IConfigurationServiceType) private configurationService: configuration.IConfigurationService;

  private hubConnection: signalR.HubConnection | null;
  private onProducts: (products: string) => void;
  private onCartReturn: () => void;
  private alreadyConstructing: boolean = false;
  private isLogging: boolean = false;

  public async build(
    logging: boolean = false
  ): Promise<SignalRService> {
    if (!this.alreadyConstructing) {
      this.alreadyConstructing = true;
      this.isLogging = logging;
      if (this.isLogging) {
        console.log('-signalR- starting...');
      }

      const apiHubUri = `${this.configurationService.get().apiUrl}\\notifications`;
      const connectionBuilder = new signalR.HubConnectionBuilder().withUrl(apiHubUri, {
        accessTokenFactory: async () => this.authenticationService.getToken(),
      });

      if (this.isLogging) {
        connectionBuilder.configureLogging(console);
      }

      this.hubConnection = connectionBuilder.build();

      this.hubConnection.on('products', (products: string) => {
        if (this.onProducts) {
          if (this.isLogging) {
            console.log(`-signalR- received products command:[${products}].`);
          }
          this.onProducts(products);
        }
      });

      this.hubConnection.on('items', () => {
        if (this.onProducts) {
          if (this.isLogging) {
            console.log('-signalR- received items command.');
          }
          this.onProducts('');
        }
      });

      this.hubConnection.on('cartreturn', () => {
        if (this.onCartReturn) {
          if (this.isLogging) {
            console.log('-signalR- received cartreturn command.');
          }
          this.onCartReturn();
        }
      });

      await this.hubConnection.start();
      if (this.isLogging) {
        console.log('-signalR- started');
      }
      this.init();
    }
    return this;
  }

  private async init(): Promise<void> {
    const user = await this.authenticationService.getUser();
    this.hubConnection.send('GetCurrentProducts', user.profile.sub);
  }

  public setOnProductsHandler(onProducts: (products: string) => void) {
    if (!this.alreadyConstructing) {
      this.onProducts = onProducts;
    }
    return this;
  }

  public setOnCartReturnHanler(onCartReturn: () => void) {
    if (!this.alreadyConstructing) {
      this.onCartReturn = onCartReturn;
    }
    return this;
  }

  public getConnection(): signalR.HubConnection {
    if (this.hubConnection == null) {
      throw 'Must call build() fist.';
    }
    return this.hubConnection;
  }
}
