import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from 'core/ioc';
import { ConfigurationService, IConfigurationServiceType } from 'core/configuration';
import './EmptyCart.scss';

interface EmptyCartProps {
  uploadHandler: Function;
  useLegacyConfiguratorApp: boolean;
}

const EmptyCart = (props: EmptyCartProps) => {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const configuration = useInjection<ConfigurationService>(IConfigurationServiceType);
  const configuratorBaseUrl = props.useLegacyConfiguratorApp ? configuration.get().configuratorLegacyBaseUrl : configuration.get().configuratorCatalogAppBaseUrl;
  const uploadTextClickHandler = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <div className="emptycart">
      <div className="emptycart__title">{t('cart.empty')}</div>
      <div className="emptycart__body">
        <div className="emptycart__text">{t('cart.empty_text')}</div>
        <div className="emptycart__actions">
          <div className="emptycart__button emptycart__configure" onClick={() => window.location.assign(configuratorBaseUrl)}>
            {t('cart.configure')}
          </div>
          <div className="emptycart__or">{t('cart.or')}</div>
          <div className="emptycart__button  emptycart__upload" onClick={uploadTextClickHandler}>
            {t('cart.upload')}
          </div>
          <input
            type="file"
            id="file"
            accept=".obk,.OBK"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={event => props.uploadHandler(event)}
          />
        </div>
      </div>
    </div>
  );
};

export { EmptyCart };
