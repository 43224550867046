import React from 'react';
import NoImage from './no-image.png';
import './ProductImage.scss';

interface ProductImageProps {
  image?: string;
}

const ProductImage = (props: ProductImageProps) => {
  const image = props.image === '' || props.image === null || props.image === undefined ? NoImage : props.image;
  return <img alt="product" className="productimage" src={image} />;
};

export { ProductImage };