import './error-pages.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import error403 from './error_403.svg';

export function Forbidden(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="hw-error-page">
      <div className="hw-error-page__container hw-centered-content">
        <img alt={t('common.errors.forbidden.title')} className="hw-error-page__logo" src={error403} />
        <div className="hw-error-page__title">{t('common.errors.forbidden.title')}</div>
        <div className="hw-error-page__description">{t('common.errors.forbidden.description')}</div>
        <Link to={'/'} className="hw-error-page__button btn btn-outline-primary">
          {t('common.actions.go_back')}
        </Link>
      </div>
    </div>
  );
}