import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../core/ioc';
import { SummaryTotals } from '../../SummaryTotals';
import { ConfigurationService, IConfigurationServiceType } from '../../../../../core/configuration';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './OrderDataPage.scss';
import { Controller, useForm } from 'react-hook-form';
import { Totals } from '../../../../models';

export type OrderFormData = {
  orderNumber: string;
  deliveryDate: Date;
  termsandconditionsCheckbox: boolean;
};

interface OrderDataPageProps {
  minimumDeliveryDate: Date;
  order: OrderFormData;
  totals: Totals;
  submit: (formData: OrderFormData) => void;
}

const OrderDataPage = ({ order, minimumDeliveryDate, totals, submit }: OrderDataPageProps) => {
  const configuration = useInjection<ConfigurationService>(IConfigurationServiceType);
  const termsAndConditionsUri = configuration.get().termsAndConditionsUri;
  const privacyPolicyUri = configuration.get().privacyPolicyUri;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OrderFormData>({
    defaultValues: order,
  });
  const { t } = useTranslation();
  return (
    <div className="confirmcheckout">
      <div className="modal__container">
        <h3>{t('cart.checkout.body')}</h3>
        <div className="confirmcheckout__section confirmcheckout__section__deliverydate">
          <div className="confirmcheckout__section__head">{t('cart.checkout.deliverydate')}</div>
          <div className="confirmcheckout__section__body">
            <Controller
              control={control}
              name="deliveryDate"
              render={({ field }) => (
                <DatePicker minDate={minimumDeliveryDate} selected={field.value} onChange={field.onChange} />
              )}
            />
          </div>
        </div>
        <div className="confirmcheckout__section ordernumber">
          <div className="confirmcheckout__section__head">{t('cart.checkout.ordernumber')}</div>
          <div className="confirmcheckout__section__body">
            <input
              // eslint-disable-next-line  no-useless-escape
              {...register('orderNumber', {
                required: true,
                maxLength: 23,
                minLength: 1,
                pattern: /^[a-z0-9A-Z.-]{1,23}$/i,
              })}
              id="orderNumber"
              name="orderNumber"
              type="text"
            />
          </div>
          {errors.orderNumber && (
            <div className="confirmcheckout__invalid">{t('cart.checkout.ordernumber_isrequired')}</div>
          )}
        </div>
        <SummaryTotals totals={totals} />
        <div className="confirmcheckout__section confirmcheckout__section__tocacceptance">
          <div className="confirmcheckout__section__body">
            <input
              {...register('termsandconditionsCheckbox', { required: true })}
              type="checkbox"
              className="confirmcheckout__section__termsandconditions"
              name="termsandconditionsCheckbox"
              id="termsandconditionsCheckbox"
              width="20"
              height="20"
            />
            <label className="confirmcheckout__section__termsandconditions__label" htmlFor="termsandconditionsCheckbox">
              {t('cart.checkout.termsandconditions_1')}
              <a href={privacyPolicyUri} rel="noopener noreferrer" target="_blank">
                {t('cart.checkout.termsandconditions_2')}
              </a>
              &nbsp;&amp;&nbsp;
              <a href={termsAndConditionsUri} rel="noopener noreferrer" target="_blank">
                {t('cart.checkout.termsandconditions_3')}
              </a>
              &nbsp;
              {t('cart.checkout.termsandconditions_4')}
            </label>
            {errors.termsandconditionsCheckbox && (
              <div className="confirmcheckout__invalid">{t('cart.checkout.termsandconditions_isrequired')}</div>
            )}
          </div>
        </div>
        <div className="confirmcheckout__section buttons">
          <div className="modal__button confirmcheckout__confirm" onClick={handleSubmit(submit)}>
            {t('cart.checkout.continue')}
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrderDataPage };
