import { PunchoutDataResponse } from '../punchout';
import { SignalRService } from './signalr.service';
import * as signalR from '@aspnet/signalr';

export interface ISignalRService {
  setOnProductsHandler(onProducts: (products: string) => void): SignalRService;
  build(logging: boolean, puchoutData: PunchoutDataResponse, needToClearCart: boolean): Promise<SignalRService>;
  getConnection(): signalR.HubConnection;
}

export type SignalRServiceProvider = (
  onProduct: (products: string) => void,
  onCartReturn: () => void
) => Promise<SignalRService>;

export const ISignalRServiceProviderType = Symbol.for('ISignalRServiceProvider');

export const ISignalRServiceType = Symbol.for('ISignalRService');
