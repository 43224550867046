import React, { useState, useEffect, useMemo } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import countries from '../../../../data/countries.json';
import { selectTheme, selectStyles, AddressForm } from '../../../address';
import { FormAddress, CustomerAddress, Customer, Address, IIndexable } from '../../../../models';
import { useOrganizationCustomers } from './useOrganizationCustomersHook';
import './CustomerDataPage.scss';
import { Option } from '../../../../utils/OptionType';
import { Loader } from '../../../../components/shared';

interface CustomerDataPageProps {
  submit: (
    billingAddress: Address,
    shippingAddress: Address,
    customerNumber: string,
    customerId: string,
  ) => Promise<void>;
}

const CustomerDataPage = ({ submit }: CustomerDataPageProps) => {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const [sameAddress, setSameAddress] = useState(true);
  const [billingAddress, setBillingAddress] = useState<FormAddress>({
    companyName: '',
    countryCode: { label: '', value: '' },
    location: '',
    postalCode: '',
    recipientName: '',
    street: '',
  });
  const [shippingAddress, setShippingAddress] = useState<FormAddress>({
    companyName: '',
    countryCode: { label: '', value: '' },
    location: '',
    postalCode: '',
    recipientName: '',
    street: '',
  });
  const [customerNumber, setCustomerNumber] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const countriesOptionList: Option[] = countries;
  const [customers, customersLoading] = useOrganizationCustomers();

  const setBillingAddressWrapper = (address: FormAddress) => {
    Object.keys(address).forEach((prop) => {
      setValue(`billing_${prop.toLocaleLowerCase()}`, (address as IIndexable)[prop], { shouldTouch: true });
    });
    setBillingAddress(address);
  };

  const setShippingAddressWrapper = (address: FormAddress) => {
    Object.keys(address).forEach((prop) => {
      setValue(`shipping_${prop.toLocaleLowerCase()}`, (address as IIndexable)[prop], { shouldTouch: true });
    });
    setShippingAddress(address);
  };

  // this effect manually enters the value for validation of the custom select for countries (when there's value).
  useEffect(() => {
    register('billing_country', { required: true });
    if (billingAddress && billingAddress?.countryCode) {
      setValue('billing_country', billingAddress.countryCode);
    }
    if (shippingAddress && shippingAddress?.countryCode) {
      setValue('shipping_country', billingAddress.countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress?.countryCode, shippingAddress?.countryCode]);

  // this effect registers or de-registers the shipping address custom country input for validation (based on sameAddress checkbox)
  useEffect(() => {
    unregister('shipping_country');
    register('shipping_country', { required: !sameAddress });
    if (!sameAddress) {
      if (shippingAddress && shippingAddress.countryCode) {
        setValue('shipping_country', billingAddress.countryCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAddress]);

  const getCountryOptionFromCode = (countryCode: string) => {
    return countries.find((c) => c.value === countryCode);
  };

  const setSelectedAddress = (selectedAddress: CustomerAddress, countrySelectName: string) => {
    const option = getCountryOptionFromCode(selectedAddress.countryCode);
    if (option) {
      let address = billingAddress;
      address = {
        companyName: selectedAddress.name,
        recipientName: selectedAddress.recipient,
        street: selectedAddress.street,
        location: selectedAddress.location,
        postalCode: selectedAddress.zipCode,
        countryCode: option,
      };
      setBillingAddressWrapper(address);
      setShippingAddressWrapper(address);
    }
    setValue(countrySelectName, option.value);
  };

  const getAddressFromCustomer = (customer: Customer): CustomerAddress => {
    return {
      name: customer.name,
      recipient: `${customer.address2} ${customer.address3}`,
      countryCode: customer.countryCode,
      location: customer.location,
      street: customer.street,
      zipCode: customer.zipCode,
    };
  };

  const convertToAddress = (address: FormAddress): Address => {
    return {
      companyName: address.companyName,
      recipientName: address.recipientName,
      street: address.street,
      postalCode: address.postalCode,
      location: address.location,
      countryCode: (address.countryCode as Option).value,
    };
  };

  const customerOptions = useMemo(() => {
    if (customersLoading) {
      return [{ label: 'loading...', value: '-1' }];
    } else {
      return customers.map((c, i) => ({ label: `${c.customerNumber} - ${c.alias} - ${c.name}`, value: `${i}` }));
    }
  }, [customers, customersLoading]);

  const customerChangeHandler = (e: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => {
    const currentValue = parseInt((e as Option).value);
    if (actionMeta.action === 'select-option' && !customersLoading) {
      const customer = customers[currentValue];
      setSelectedAddress(getAddressFromCustomer(customer), 'billing_country');
      setCustomerId(customer.id);
      setCustomerNumber(customer.customerNumber);
      setValue('customer_number', customer.customerNumber);
    }
  };

  const bodyClass = sameAddress ? 'address__content__body' : 'address__content__body address__content__body__fullwidth';
  const shippingClass = sameAddress
    ? 'address__content address__content__shipping'
    : 'address__content address__content__billing__shown';

  const submitCheckout = () => {
    setIsCheckoutLoading(true);
    submit(convertToAddress(shippingAddress), convertToAddress(billingAddress), customerNumber, customerId).then(() =>
      setIsCheckoutLoading(false),
    );
  };

  useEffect(() => {
    register('customer_number', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>{t('address_form.select_organization')}</h3>
      <Select
        className="address__select"
        name="customers"
        options={customerOptions}
        theme={selectTheme}
        styles={selectStyles}
        onChange={customerChangeHandler}
      />
      {errors.customer_number && <span className="address__invalid">{t('address_form.customer_number_required')}</span>}
      <div className={bodyClass}>
        <div className="address__content address__content__billing">
          <h3>{t('address_form.address_hint')}</h3>
          <AddressForm
            formState={billingAddress}
            setFormState={setBillingAddress}
            prefix={'billing'}
            formHook={{ errors, register, setValue, unregister }}
            selectTheme={selectTheme}
            selectStyles={selectStyles}
            countriesOptionList={countriesOptionList}
            required={true}
          />
          <div className="address__same">
            <input
              type="checkbox"
              className="address__same__checkbox"
              name="sameaddress"
              id="sameaddress_checkbox"
              width="20"
              height="20"
              checked={sameAddress}
              onChange={(e) => {
                setSameAddress(e.target.checked);
              }}
            />
            <label className="address__same__label" htmlFor="sameaddress_checkbox">
              {t('address_form.use_same_shipping')}
            </label>
          </div>
        </div>
        <div className={shippingClass}>
          <h3>{t('address_form.address_hint4')}</h3>
          <AddressForm
            formState={shippingAddress}
            setFormState={setShippingAddress}
            formHook={{ errors, register, setValue, unregister }}
            prefix={'shipping'}
            selectTheme={selectTheme}
            selectStyles={selectStyles}
            countriesOptionList={countriesOptionList}
            required={!sameAddress}
          />
        </div>
      </div>
      {isCheckoutLoading ? (
        <div className="modal__button address__confirm address__confirm__disabled">
          <div>{t('addresses.confirm')}</div> <Loader isButtonAnimation={true} />
        </div>
      ) : (
        <div className="modal__button address__confirm" onClick={handleSubmit(submitCheckout)}>
          {t('addresses.confirm')}
        </div>
      )}
    </>
  );
};

export { CustomerDataPage };
