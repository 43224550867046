import React, { useState } from 'react';
import './ProductNameTree.scss';
import chevronIcon from '../shared/lnr-chevron-right.svg';
import { Product } from 'app/models';
import { useTranslation } from 'react-i18next';

interface ProductNameTreeProps {
  name: string;
  isroot: boolean;
  subItems: Product[] | null;
}

const ProductNameTree = (props: ProductNameTreeProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const iconClassName = expanded
    ? 'productnametree__chevron__icon productnametree__chevron__icon__expanded'
    : 'productnametree__chevron__icon';
  let titleClassName =
    props.subItems !== null ? 'productnametree__title__withchildren' : 'productnametree__title__withoutchildren';

  titleClassName += props.isroot ? '' : ' productnametree__subtitle';
  const toggleTree = () => {
    setExpanded(!expanded);
  };

  const childrenClassName = expanded
    ? 'productnametree__padder productnametree__shown'
    : 'productnametree__padder productnametree__hidden';
  return (
    <div className="productnametree">
      <div className={titleClassName} onClick={toggleTree}>
        {props.subItems !== null ? (
          <div className={iconClassName}>
            <img alt={t('product.has_subproducts')}
              className={props.isroot ? 'productnametree__rooticon' : 'productnametree__subicon'}
              src={chevronIcon}
              title={t('product.has_subproducts')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={props.isroot ? 'productnametree__rootname' : 'productnametree__subname'}>{props.name}</div>
      </div>
      <div className={childrenClassName}>
        {props.subItems !== null ? (
          props.subItems.map(subitem => (
            <ProductNameTree key={subitem.id} name={subitem.name} isroot={false} subItems={subitem.subItems} />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};


export { ProductNameTree };
