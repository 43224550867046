import { ContainerModule, interfaces } from 'inversify';
import { IAuthenticationServiceType, IAuthenticationService, AuthenticationService } from './authentication';
import { ConfigurationService, IConfigurationService, IConfigurationServiceType } from './configuration';
import { IHttpFactoryType, HttpFactory, IHttpFactory } from './http';
import { IInsightsService, IInsightsServiceType, AppInsightsService } from './insights';
import { IPunchoutDataService, IPunchoutDataServiceType, PunchoutDataService } from './punchout';
import {
  SignalRService,
  SignalRServiceProvider,
  ISignalRServiceProviderType,
  ISignalRService,
  ISignalRServiceType,
} from './signalr';

export const coreContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthenticationService>(IAuthenticationServiceType)
    .to(AuthenticationService)
    .inSingletonScope();

  bind<IConfigurationService>(IConfigurationServiceType)
    .to(ConfigurationService)
    .inSingletonScope();

  bind<IHttpFactory>(IHttpFactoryType)
    .to(HttpFactory)
    .inSingletonScope();

  bind<IInsightsService>(IInsightsServiceType)
    .to(AppInsightsService)
    .inSingletonScope();

  bind<ISignalRService>(ISignalRServiceType)
    .to(SignalRService)
    .inSingletonScope();

  bind<IPunchoutDataService>(IPunchoutDataServiceType)
    .to(PunchoutDataService)
    .inSingletonScope();

  bind<SignalRServiceProvider>(ISignalRServiceProviderType).toProvider<SignalRService>(context => {
    return (onProduct: (products: string) => void, onCartReturn: () => void) => {
      return new Promise<SignalRService>(async resolve => {
        const singleton = await context.container
          .get<ISignalRService>(ISignalRServiceType)
          .setOnProductsHandler(onProduct)
          .setOnCartReturnHanler(onCartReturn)
          .build(true);
        resolve(singleton);
      });
    };
  });
});
