import { IHttpFactory, IHttp } from '.';
import React from 'react';
import axios, { CancelTokenSource, CancelToken } from 'axios';
import { injectable, inject } from 'inversify';
import * as authentication from '../authentication';
import { toast } from 'react-toastify';
import HttpStatus from 'http-status-codes';
import i18n from 'i18n';
import { createBrowserHistory } from 'history';
import * as configuration from 'core/configuration';

@injectable()
export class HttpFactory implements IHttpFactory {
  @inject(authentication.IAuthenticationServiceType)
  private authenticationService: authentication.IAuthenticationService;
  @inject(configuration.IConfigurationServiceType)
  private configurationService: configuration.IConfigurationService;
  public build(api: boolean = false): IHttp {
    let http = null;
    if (api) {
      const config = this.configurationService.get();
      http = axios.create({ baseURL: config.apiUrl });
    } else {
      http = axios.create();
    }
    const history = createBrowserHistory();

    http.interceptors.request.use(async (config) => {
      const accessToken = await this.authenticationService.getToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    http.interceptors.response.use(
      (response) => response.data,
      (error) => {
        const httpStatus = error?.response?.status;
        if (httpStatus === HttpStatus.INTERNAL_SERVER_ERROR) {
          toast.error(i18n.t('common.errors.internal_server_error'));
        } else if (httpStatus === HttpStatus.FORBIDDEN) {
          history.push('/forbidden');
        }

        return Promise.reject(error);
      },
    );

    return http;
  }
  public getCancelTokenSource(): CancelTokenSource {
    return axios.CancelToken.source();
  }
}

export function renewCancelToken(ref: React.MutableRefObject<CancelTokenSource>): CancelToken {
  if (ref.current) {
    ref.current.cancel();
  }
  ref.current = axios.CancelToken.source();

  return ref.current.token;
}
