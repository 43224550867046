import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CheckoutButton.scss';
import { Loader } from '../shared';
import { PunchoutDataContext } from '../../../core/PunchoutDataContext';
import { PunchoutOperation } from 'core/punchout';


interface CheckoutButtonProps {
  onCheckout: Function;
  onPunchout: (sessionReturnUrl: string) => void;
  onInspect: Function;
}

const CheckoutButton = (props: CheckoutButtonProps) => {
  const { t } = useTranslation();
  const [isCartReturn, setIsCartReturn] = useState(false);
  const punchoutData = useContext(PunchoutDataContext);

  if (punchoutData !== null && punchoutData.punchOutOperationType === PunchoutOperation.Inspect) {
    return (
      <div className="checkoutbutton" onClick={() => props.onInspect()}>
        {t('cart.inspect_label')}
      </div>
    );
  }

  return punchoutData !== null ? (
    <div
      className="checkoutbutton"
      onClick={() => {
        setIsCartReturn(true);
        props.onPunchout(punchoutData.punchOutSessionReturnUrl);
      }}
    >
      {t('cart.punchout_label')} {isCartReturn && <Loader isButtonAnimation={true} />}
    </div>
  ) : (
    <div className="checkoutbutton" onClick={() => props.onCheckout()}>
      {t('cart.checkout_label')}
    </div>
  );
};

export { CheckoutButton };
