import './polyfills';
import './styles/app.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { configureI18N } from './i18n';
import { container } from './container';
import { IoCProvider } from './core/ioc';
import './register-web-components';
import { IInsightsServiceType, IInsightsService } from './core/insights';
import * as serviceWorker from './serviceWorker';
import { Configuration, IConfigurationService, IConfigurationServiceType } from './core/configuration';
import { Loader } from './app/components/shared/loader/Loader';
import { ServiceBar, useSigninRedirect } from './app/components/shared';
import { AppRouter } from './app/AppRouter';
import { analyticsService } from './core/analytics';

const configurationService = container.get<IConfigurationService>(IConfigurationServiceType);
const insightsService = container.get<IInsightsService>(IInsightsServiceType);
interface AppProps {
  configuration: Configuration;
}
const App = ({ configuration }: AppProps) => {
  const isSignInRedirect = useSigninRedirect();
  return (
    <IoCProvider container={container}>
      <Suspense fallback={<Loader isReturningCart={false} />}>
        <ServiceBar
          clientId={configuration.serviceBar.clientId}
          scope={configuration.serviceBar.clientScope}
          settingsUri={configuration.serviceBar.serviceBarSettingsUri}
        />
        {!isSignInRedirect && <AppRouter endPunchoutSessionEndpoint={configuration.endPunchoutSessionEndpoint} />}
      </Suspense>
    </IoCProvider>
  );
};
const initAsync = async () => {
  await configurationService.load();
  insightsService.load();
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  const configuration = configurationService.get();
  await configureI18N(configuration.suiteOrigin);

  analyticsService.init(configuration);

  root.render(<App configuration={configuration} />);
  serviceWorker.unregister();
};

initAsync();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
