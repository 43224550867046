import React from 'react';
import './ProductPrice.scss';
import { moneyFormatter } from '../../utils';
import { useTranslation } from 'react-i18next';

interface ProductEcoProps {
  value: number;
  currency: string;
}

const ProductEcoTax = (props: ProductEcoProps) => {
  const formatValue = ({ value, currency }: ProductEcoProps) => {
    return moneyFormatter({ amount: value, currency });
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className="product__price__label product__tax">{t('product.ecotax')}</div>
      <div className="product__price__value product__tax">{formatValue(props)}</div>
    </div>
  );
};

export { ProductEcoTax };
