import { injectable } from 'inversify';
import { IAuthenticationService } from './iauthentication.service';
import { User } from 'oidc-client';
import jwtDecode from 'jwt-decode';
import { PunchoutOperation } from '../../core/punchout';
import 'haworth-service-bar';

export enum RoleTypes {
  DEFAULT = 'DEFAULT',
  ORGANIZATION_ADMINISTRATOR = 'ORGANIZATION_ADMINISTRATOR',
  ORGANIZATION_MASTER = 'ORGANIZATION_MASTER',
  DISCOUNTS_ADMINISTRATOR = 'DISCOUNTS_ADMINISTRATOR',
  MASTER = 'MASTER',
}

export interface AppUser extends User {
  role: RoleTypes[];
  organization_id?: string;
  operation: PunchoutOperation;
  cartId: string;
}

@injectable()
export class AuthenticationService implements IAuthenticationService {
  private appUser: User | null = null;
  private get bar() {
    // eslint-disable-next-line no-undef
    return document.querySelector('service-bar') as HTMLServiceBarElement;
  }

  private async user(): Promise<User> {
    if (this.appUser === null) {
      if (this.bar.userHasLicence('Shop')) {
        const user = await this.bar.getUser();
        this.appUser = user;
      }
    }
    return this.appUser;
  }

  public login(): void {
    const event = new Event('requestSBUserLogin');
    this.bar.dispatchEvent(event);
  }

  public async getUser(): Promise<AppUser> {
    const user = await this.user();
    if (!user) {
      return null;
    }
    const decoded = jwtDecode<{
      role: string[];
      organization_id: string;
    }>(user.access_token);
    const result = {
      ...user,
      role:
        !decoded.role || decoded.role.length === 0
          ? [RoleTypes.DEFAULT]
          : Array.isArray(decoded.role)
            ? decoded.role.map((role) => role as RoleTypes)
            : [decoded.role as RoleTypes],
    } as AppUser;

    return result;
  }

  public async getToken(): Promise<string> {
    const user = await this.user();
    if (user) {
      return user.access_token;
    } else {
      return null;
    }
  }

  async isAuthenticated(): Promise<boolean> {
    const user = await this.user();
    return user ? true : false;
  }
}
