import { Configuration } from '../configuration';
import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';

class GoogleTagManagerService {
  private tracking = false;
  private tagManagerArgs: TagManagerArgs = null;

  public async init(configuration: Configuration) {
    const googleTagManagerTrackingId: string = configuration.googleTagManagerTrackingId;
    if (googleTagManagerTrackingId) {
      this.tagManagerArgs = { gtmId: googleTagManagerTrackingId };
      TagManager.initialize(this.tagManagerArgs);
      this.tracking = true;
    }
  }

  public async pageView(pagePath: string, pageTitle: string) {
    if (this.tracking) {
      const data: DataLayerArgs = {
        dataLayer: {
          event: 'Pageview',
          pagePath: pagePath,
          pageTitle: pageTitle,
        },
      };
      TagManager.dataLayer(data);
    }
  }
}

export const googleTagManagerService = new GoogleTagManagerService();
