import React, { PropsWithChildren, MouseEvent as ReactMouseEvent } from 'react';
import ReactModal from 'react-modal';
import closeIcon from '../close.svg';
import './Modal.scss';

interface ModalProps {
  isOpen: boolean;
  closeHandler: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Modal = (props: PropsWithChildren<ModalProps>) => {
  ReactModal.setAppElement('#root');
  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 41, 59, 0.8)',
    },
    content: {
      bottom: 'auto',
      fontWeight: 400,
      marginRight: '-50%',
      maxHeight: '100vh',
      left: '50%',
      padding: '18px',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <ReactModal isOpen={props.isOpen} style={modalStyle}>
      <div className="modal__container">
        <div className="modal__close" onClick={props.closeHandler}>
          <img alt="close" src={closeIcon} />
        </div>
        <div>{props.children}</div>
      </div>
    </ReactModal>
  );
};

export { Modal };
