import React, { useState, MouseEvent as ReactMouseEvent } from 'react';
import { Modal } from '../../shared';
import { Address, Totals } from '../../../models';
import { OrderDataPage, OrderFormData } from './pages/OrderDataPage';
import { ErrorPage } from './pages/ErrorPage';
import { SuccessPage } from './pages/SuccessPage';
import { CustomerDataPage } from './pages/CustomerDataPage';

enum CheckoutState {
  OrderDetails = 0,
  CustomerDetails = 1,
  Error = 3,
  Success = 4,
}

interface ConfirmCheckoutModalProps {
  isOpen: boolean;
  closeHandler: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  checkoutHandler: (
    date: string,
    orderNumber: string,
    customerId: string,
    customerNumber: string,
    shippingAddress: Address,
    billingAddress: Address,
  ) => Promise<void>;
  updateCart: Function;
  totals: Totals;
  isCheckoutSucceed: boolean;
}

const ConfirmCheckoutModal = (props: ConfirmCheckoutModalProps) => {
  const [currentCheckoutState, setCurrentCheckoutState] = useState(CheckoutState.OrderDetails);
  const [error, setError] = useState('');
  const now = new Date();
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  let minimumDelivery = new Date(now.getTime() + 45 * 24 * 60 * 60 * 1000);
  while (!isWeekday(minimumDelivery)) {
    minimumDelivery = new Date(minimumDelivery.getTime() + 1 * 24 * 60 * 60 * 1000);
  }
  const [order, setOrderState] = useState<OrderFormData>({
    orderNumber: '',
    deliveryDate: minimumDelivery,
    termsandconditionsCheckbox: false,
  });

  const close = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    setCurrentCheckoutState(CheckoutState.OrderDetails);
    props.closeHandler(e);
  };

  const submit = async (
    shippingAddress: Address,
    billingAddress: Address,
    customerNumber: string,
    customerId: string,
  ) => {
    await props
      .checkoutHandler(
        order.deliveryDate.toISOString(),
        order.orderNumber,
        customerId,
        customerNumber,
        shippingAddress,
        billingAddress,
      )
      .then(() => setCurrentCheckoutState(CheckoutState.Success))
      .catch((error) => {
        if (error.response?.data?.validationFailures) {
          if (Array.isArray(error.response.data.validationFailures) && error.response.data.validationFailures[0]) {
            const failure = error.response.data.validationFailures[0];
            setError(`(${failure.propertyName}): ❌ ${failure.errorMessage}`);
          }
        }
        setCurrentCheckoutState(CheckoutState.Error);
      });
    setOrderState({
      orderNumber: '',
      deliveryDate: minimumDelivery,
      termsandconditionsCheckbox: false,
    });
  };

  const continueHandler = (formData: OrderFormData) => {
    setOrderState(formData);
    setCurrentCheckoutState(CheckoutState.CustomerDetails);
  };

  const renderForm = (currentCheckoutState: CheckoutState) => {
    switch (currentCheckoutState) {
      default:
      case CheckoutState.OrderDetails:
        return (
          <OrderDataPage
            minimumDeliveryDate={minimumDelivery}
            order={order}
            totals={props.totals}
            submit={continueHandler}
          />
        );
      case CheckoutState.CustomerDetails:
        return <CustomerDataPage submit={submit} />;
      case CheckoutState.Error:
        return <ErrorPage errorMessage={error} />;
      case CheckoutState.Success:
        return <SuccessPage />;
    }
  };

  const images = ['illustration-success.svg', 'illustration-error.svg'];

  images.forEach((imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  });

  return (
    <Modal isOpen={props.isOpen || props.isCheckoutSucceed} closeHandler={close}>
      {renderForm(currentCheckoutState)}
    </Modal>
  );
};

export { ConfirmCheckoutModal };
