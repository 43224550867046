import { AppUser } from '.';

export interface IAuthenticationService {
  login(): void;
  getUser(): Promise<AppUser>;
  getToken(): Promise<string>;
  isAuthenticated(): Promise<boolean>;
}

export const IAuthenticationServiceType = Symbol.for('IAuthenticationService');
