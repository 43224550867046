import React from 'react';
import { useTranslation } from 'react-i18next';
interface NoticesProps {
  notices: string[];
}
export const Notices = (props: NoticesProps) => {
  const { t } = useTranslation();
  const { notices } = props;
  return (
    <>
      <h3>{t('summary.labels.notices')}</h3>
      {notices.map((notice, i) => (
        <div key={i}>{notice}</div>
      ))}
    </>
  );
};

export default Notices;