import React, { useContext } from 'react';
import { Product as ProductModel } from '../../models';
import { CartSearchBar } from './CartSearchBar';
import { useTranslation } from 'react-i18next';
import { Loader } from '../shared/loader/Loader';
import { Product } from '../product/Product';
import { ClearButton } from './ClearButton';
import { ConfigureNewProductButton } from './ConfigureNewProductButton';
import './Cart.scss';
import { PunchoutDataContext } from 'core/PunchoutDataContext';
import { PunchoutOperation } from 'core/punchout';

interface CartProps {
  loading: boolean;
  items: ProductModel[];
  pconSessionId: string;
  publicObkUri: string;
  clearHandler: Function;
  deleteProduct: (productId: string) => Promise<void>;
  updateProductQuantity: (productId: string, productQuantity: number) => Promise<void>;
  useLegacyConfiguratorApp: boolean;
}

const Cart = (props: CartProps) => {
  const { loading, items } = props;
  const { t } = useTranslation();
  const punchoutContext = useContext(PunchoutDataContext);
  const editable = punchoutContext?.punchOutOperationType !== PunchoutOperation.Inspect;
  const productEditable = punchoutContext?.canEditCartProducts;

  return (
    <>
      <div className="cart">
        {loading ? (
          <Loader isReturningCart={false} />
        ) : (
          <>
            <div className="cart__header__top">
              <ConfigureNewProductButton useLegacyConfiguratorApp={props.useLegacyConfiguratorApp}></ConfigureNewProductButton>
            </div>
            <div className="cart__header__main">
              <div className="cart__header_left">
                <div className="cart__name">{t('cart.header')}</div>
                <div className="cart__itemcount">{items.length + ' ' + t('cart.positions')}</div>
              </div>
              <div className="cart__search">
                <CartSearchBar />
              </div>
              <div className="cart__header__clearproducts">
                {editable && <ClearButton clearHandler={props.clearHandler} />}
              </div>
            </div>
            <div className="cart__body">
              {items
                .sort(function (a, b) {
                  return a.orderPosition - b.orderPosition;
                })
                .map((item, idx) => (
                  <Product
                    key={item.id}
                    number={idx + 1}
                    product={item}
                    deleteProduct={props.deleteProduct}
                    productDeleteable={editable}
                    quantityEditable={editable}
                    productEditable={editable && productEditable}
                    updateProductQuantity={props.updateProductQuantity}
                    useLegacyConfiguratorApp={props.useLegacyConfiguratorApp}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { Cart };
