import React from 'react';
import { ProductEcoTax, ProductImage, ProductQuantity, ProductPrice } from '.';
import { useTranslation } from 'react-i18next';
import { ProductConfigureButton } from './ProductConfigureButton';
import { ProductDeleteButton } from './ProductDeleteButton';
import { ProductNameTree } from './ProductNameTree';
import { Product as ProductModel } from '../../models';
import './Product.scss';

interface ProductProps {
  product: ProductModel;
  number: number;
  quantityEditable: boolean;
  productDeleteable: boolean;
  productEditable: boolean;
  updateProductQuantity: (productId: string, productQuantity: number) => Promise<void>;
  deleteProduct: (productId: string) => Promise<void>;
  useLegacyConfiguratorApp: boolean;
}

const Product = (props: ProductProps) => {
  const {
    currency,
    id,
    image,
    name,
    originalTotalNetPrice,
    quantity,
    showDiscount,
    subItems,
    totalEcoTaxPrice,
    totalNetPrice,
    subtotalNetPrice,
  } = props.product;

  const { quantityEditable, productDeleteable, productEditable } = props;

  let summatoryNetPrice = totalNetPrice;

  const addSubItems = (original: number, subItems: ProductModel[]) => {
    if (subItems && subItems.length > 0) {
      subItems.forEach((element) => {
        original += element.totalNetPrice;
        original = addSubItems(original, element.subItems);
      });
    }
    return original;
  };

  if (subItems) {
    summatoryNetPrice = addSubItems(summatoryNetPrice, subItems);
  }

  const { t } = useTranslation();
  return (
    <>
      <div className="product">
        <div className="product__number">#{props.number}</div>
        <div className="product__image">
          <ProductImage image={image} />
        </div>
        <div className="product__body">
          <ProductNameTree name={name} isroot={true} subItems={subItems} />
          <div className="flex__span"></div>
          <div className="product__data">
            <div className="product__info product__quantity">
              <span className="product__data__label__first">{t('product.quantity')}</span>
              <ProductQuantity
                value={quantity}
                setValue={(val: number) => props.updateProductQuantity(id, val)}
                disabled={!quantityEditable}
              />
            </div>
            <div className="product__buttons__delete">
              {productDeleteable && <ProductDeleteButton deleteProduct={() => props.deleteProduct(id)} />}
            </div>

            {/* <div className="product__info product__grossvalue">
              <span className="product__data__label">{t('product.value_gross')}</span>
              <ProductPrice value={totalGrossPrice} currency={currency} />
            </div> */}
          </div>
        </div>
        <div className="product__buttons">
          <div className="product__buttons__configure">{productEditable && <ProductConfigureButton itemId={id} useLegacyConfiguratorApp={props.useLegacyConfiguratorApp} />}</div>
          <div>
            <div className="product__flexrow__spaced">
              {totalEcoTaxPrice !== 0 && (
                <div className="product__flexcol">
                  <ProductEcoTax value={totalEcoTaxPrice} currency={currency} />
                </div>
              )}
              <div className="product__flexcol">
                <div className="product__price__label product__net">{t('product.item_price')}</div>
                {!showDiscount && (
                  <div className="product__info product__netvalue product__price__value product__net">
                    <ProductPrice value={summatoryNetPrice} currency={currency} />
                  </div>
                )}
                {showDiscount && (
                  <div>
                    <div className="product__info product__oldnetvalue product__price__value product__net">
                      <ProductPrice value={originalTotalNetPrice} currency={currency} />
                    </div>
                    <div className="product__info product__netvalue product__price__value product__discount product__net">
                      <ProductPrice value={summatoryNetPrice} currency={currency} />
                    </div>
                  </div>
                )}
              </div>
              <div className="product__flexcol__spaced">
                <div className="product__total__label">{t('product.sum')}</div>
                <div className="product__info product__netvalue product__total__value">
                  <ProductPrice value={subtotalNetPrice} currency={currency} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Product };
