import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { RoleTypes } from '../../../core/authentication/authentication.service';
import { UserContext } from '../../../core/UserContext';

interface IProps extends React.PropsWithChildren {
  allowedRoles?: RoleTypes[];
}

export function RequireAuth({ allowedRoles = [], children }: IProps): React.ReactNode {
  const { role } = useContext(UserContext);

  const hasAllowedRole = !allowedRoles.length || allowedRoles.some((r) => role.indexOf(r) !== -1);

  if (!hasAllowedRole) {
    return <Navigate to={'/unauthorized'} />;
  } else {
    return children;
  }
}
