import React, { useEffect } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { Forbidden, Loader, NotFound, RequireAuth, Header, ServiceFooter } from './components/shared';
import { analyticsService } from '../core/analytics';
import { CartPage } from './pages/CartPage';
import { useCheckAuthentication } from '../core/useCheckAuthentication';
import { UserContext } from '../core/UserContext';
import { PunchoutDataContext } from '../core/PunchoutDataContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePunchoutSession } from './hooks/usePunchoutSession';

export function AppRouter({ endPunchoutSessionEndpoint } : { endPunchoutSessionEndpoint : string }): React.JSX.Element {
  const { appUser } = useCheckAuthentication();
  const [, punchoutData, isPunchoutSessionLoading] = usePunchoutSession();

  useEffect(() => {
    analyticsService.pageView(window.location.pathname, window.location.pathname);
  }, []);


  if (!appUser || isPunchoutSessionLoading) {
    return (
      <main className="hw-page-content mt-5">
        <Loader isReturningCart={false} />
      </main>
    );
  }

  return (
    <BrowserRouter>
      <PunchoutDataContext.Provider value={punchoutData}>
        <UserContext.Provider value={appUser}>
          <div className="App">
            <header>
              <Header />
            </header>
            <div className="main__container">
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <CartPage endPunchoutSessionEndpoint={endPunchoutSessionEndpoint} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/not-found"
                  element={
                    <RequireAuth>
                      <NotFound />
                    </RequireAuth>
                  }
                />
                <Route path="/unauthorized" element={<Forbidden />} />
                <Route path="*" element={<Navigate to="/not-found" />} />
              </Routes>
            </div>
            <ServiceFooter />
          </div>
          <ToastContainer />
        </UserContext.Provider>
      </PunchoutDataContext.Provider>
    </BrowserRouter>
  );
}
