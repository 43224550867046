import { useEffect, useState } from 'react';
import { IPunchoutDataService, IPunchoutDataServiceType, PunchoutDataResponse } from 'core/punchout';
import { useInjection } from 'core/ioc';

type UsePunchoutSessionHookResult = [boolean, PunchoutDataResponse | null, boolean];

const usePunchoutSession = (): UsePunchoutSessionHookResult => {
  const [punchoutData, setPunchoutData] = useState<PunchoutDataResponse>(null);
  const [isPunchout, setIsPunchout] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const punchoutDataService = useInjection<IPunchoutDataService>(IPunchoutDataServiceType);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const isPunchoutSession = await punchoutDataService.getIsPunchout();
      if (isPunchoutSession.isPunchout) {
        setIsPunchout(true);
        setPunchoutData(isPunchoutSession.data);
        setLoading(false);
      } else {
        setIsPunchout(false);
        setPunchoutData(null);
        setLoading(false);
      }
    })();
  }, [punchoutDataService]);

  return [isPunchout, punchoutData, loading];
};


export { usePunchoutSession };
