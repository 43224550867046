import { useState } from 'react';
import { Address, Totals, Product } from '../models';
import { useInjection } from 'core/ioc';
import { IHttpFactory, IHttpFactoryType } from 'core/http';

export interface CartEndpointResult {
  id: string;
  pconSessionId: string;
  publicObkUri: string;
  addresses: {
    shipping: Address;
    billing: Address;
  };
  totals: Totals;
  basketProducts: Product[];
}
type UserCartHookResult = [CartEndpointResult | null, boolean, Function, Function];

const useUserCart = (): UserCartHookResult => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<CartEndpointResult>(null);
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const http = httpFactory.build(true);

  function init() {
    setResult(null);
    setLoading(true);
  }

  async function load() {
    init();
    const response = await http.get<CartEndpointResult | null>('/v1/Orders');
    if (response?.basketProducts?.length) {
      setResult(response);
    }
    setLoading(false);
  }
  return [result, loading, load, setLoading];
};

export { useUserCart };
