import React from 'react';
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src';
import { Option } from '../../utils/OptionType';
import { useTranslation } from 'react-i18next';
import { FormAddress } from '../../models';

interface AddressFormProps {
  formState?: FormAddress;
  setFormState: Function;
  selectTheme: any;
  selectStyles: any;
  countriesOptionList: Option[];
  formHook: {
    setValue: Function;
    register: Function;
    unregister: Function;
    errors: any;
  };
  prefix: string;
  required: boolean;
}

const AddressForm = (props: AddressFormProps) => {
  const { selectTheme, selectStyles, countriesOptionList, formHook, prefix, required, formState } = props;
  const { t } = useTranslation();

  const setCompanyName = (value: string) => {
    props.setFormState({ ...formState, companyName: value });
  };
  const setRecipientName = (value: string) => {
    props.setFormState({ ...formState, recipientName: value });
  };
  const setStreet = (value: string) => {
    props.setFormState({ ...formState, street: value });
  };
  const setCountryCode = (value: Option) => {
    props.setFormState({ ...formState, countryCode: value });
  };
  const setLocation = (value: string) => {
    props.setFormState({ ...formState, location: value });
  };
  const setPostalCode = (value: string) => {
    props.setFormState({ ...formState, postalCode: value });
  };

  return (
    <form className="address__form">
      <label className="address__label" htmlFor={`${prefix}_companyname`}>
        {t('address_form.company_name_label')}
      </label>
      <input
        className="address__input"
        placeholder={t('address_form.company_name_placeholder')}
        {...formHook.register(`${prefix}_companyname`, { required: required })}
        type="text"
        name={`${prefix}_companyname`}
        id={`${prefix}_companyname`}
        value={formState?.companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />
      {formHook.errors[`${prefix}_companyname`] && (
        <span className="address__invalid">{t('address_form.company_name_isrequired')}</span>
      )}
      <label className="address__label" htmlFor={`${prefix}_recipientname`}>
        {t('address_form.recipient_name_label')}
      </label>
      <input
        className="address__input"
        placeholder={t('address_form.recipient_name_placeholder')}
        type="text"
        name={`${prefix}_recipientname`}
        id={`${prefix}_recipientname`}
        value={formState?.recipientName}
        onChange={(e) => setRecipientName(e.target.value)}
      />
      <label className="address__label" htmlFor={`${prefix}_street`}>
        {t('address_form.street_label')}
      </label>
      <input
        className="address__input"
        placeholder={t('address_form.street_placeholder')}
        type="text"
        name={`${prefix}_street`}
        id={`${prefix}_street`}
        {...formHook.register(`${prefix}_street`, { required: required })}
        value={formState?.street}
        onChange={(e) => setStreet(e.target.value)}
      />
      {formHook.errors[`${prefix}_street`] && (
        <span className="address__invalid">{t('address_form.street_isrequired')}</span>
      )}
      <label className="address__label" htmlFor={`${prefix}_country`}>
        {t('address_form.country_label')}
      </label>
      <Select
        className="address__select"
        options={countriesOptionList}
        maxMenuHeight={150}
        value={formState?.countryCode}
        theme={selectTheme}
        id={`${prefix}_country`}
        styles={selectStyles}
        onChange={(e: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => {
          const currentValue = e as Option;
          props.formHook.setValue(`${prefix}_country`, currentValue.value);
          if (actionMeta.action === 'select-option') {
            setCountryCode(currentValue);
          }
        }}
      />
      {formHook.errors[`${prefix}_country`] && (
        <span className="address__invalid">{t('address_form.country_isrequired')}</span>
      )}
      <label className="address__label" htmlFor={`${prefix}_location`}>
        {t('address_form.location_label')}
      </label>
      <input
        placeholder={t('address_form.location_placeholder')}
        className="address__input"
        type="text"
        name={`${prefix}_location`}
        id={`${prefix}_location`}
        value={formState?.location}
        {...formHook.register(`${prefix}_location`, { required: required })}
        onChange={(e) => setLocation(e.target.value)}
      />
      {formHook.errors[`${prefix}_location`] && (
        <span className="address__invalid">{t('address_form.location_isrequired')}</span>
      )}
      <label className="address__label" htmlFor={`${prefix}_postalcode`}>
        {t('address_form.postalcode_label')}
      </label>
      <input
        placeholder={t('address_form.postalcode_placeholder')}
        className="address__input"
        type="text"
        name={`${prefix}_postalcode`}
        id={`${prefix}_postalcode`}
        value={formState?.postalCode}
        {...formHook.register(`${prefix}_postalcode`, { required: required })}
        onChange={(e) => setPostalCode(e.target.value)}
      />
      {formHook.errors[`${prefix}_postalcode`] && (
        <span className="address__invalid">{t('address_form.postalcode_isrequired')}</span>
      )}
      <span className="address__requiredfields">{t('address_form.required_hint')}</span>
    </form>
  );
};

export { AddressForm };
