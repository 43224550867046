import { inject, injectable } from 'inversify';
import { IsPunchoutResponse } from './punchout.models';
import * as http from '../http';
import { IPunchoutDataService } from './ipunchout.service';
import * as configuration from 'core/configuration';

@injectable()
export class PunchoutDataService implements IPunchoutDataService {
  @inject(configuration.IConfigurationServiceType)
  private _configuration: configuration.IConfigurationService;
  @inject(http.IHttpFactoryType)
  private _httpFactory: http.IHttpFactory;

  async getIsPunchout(): Promise<IsPunchoutResponse> {
    const { identityUrl } = this._configuration.get();
    const httpClient = this._httpFactory.build();
    const response = await httpClient.get<IsPunchoutResponse>(`${identityUrl}/api/v1/punchout/session`);
    return response;
  }
}
