import { useState, useEffect } from 'react';
import { AppUser } from './authentication/authentication.service';
import { IAuthenticationService, IAuthenticationServiceType } from './authentication/iauthentication.service';
import { useInjection } from './ioc';

interface useCheckAuthenticationResult {
  appUser: AppUser;
}

export function useCheckAuthentication(): useCheckAuthenticationResult {
  const [appUser, setAppUser] = useState<AppUser>(null);

  const authenticationService = useInjection<IAuthenticationService>(IAuthenticationServiceType);

  useEffect(() => {
    async function checkLogin(): Promise<void> {
      const isAuthenticated = await authenticationService.isAuthenticated();
      if (!isAuthenticated) {
        authenticationService.login();
      }
      const userInfo = await authenticationService.getUser();
      setAppUser(userInfo);
    }

    checkLogin();
  }, [authenticationService]);

  return { appUser };
}
