import React from 'react';
import { useTranslation } from 'react-i18next';
import error from './illustration-error.svg';
import './Modals.scss';

const ErrorPage = ({ errorMessage }: { errorMessage: string }) => {
  const { t } = useTranslation();
  return (
    <div className="modal__text">
      <h3>{t('cart.error')}</h3>
      <div>
        <img alt={''} className="confirmcheckout__illustration" src={error} />
        {errorMessage ? (
          <p className="modal__errormessage">{errorMessage}</p>
        ) : (
          <p className="modal__text">{t('cart.error_text')}</p>
        )}
      </div>
    </div>
  );
};

export { ErrorPage };
