import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const acceptedLanguages = ['de', 'en', 'fr', 'pt', 'zh-CN', 'ja' ];
interface PoeditorResponse {
  response: { status: string; code: string; message: string };
  result: {
    terms: [
      {
        term: string;
        context: string;
        translation: {
          content: string;
        };
      },
    ];
  };
}
export async function configureI18N(suiteOrigin: string) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next);
  await i18n.init({
    backend: {
      loadPath: '/locales?lng={{lng}}&ns={{ns}}',
      allowMultiLoading: true,
      parse: (data: string): object => {
        const response: PoeditorResponse = JSON.parse(data);
        const languageData = {};
        response.result.terms.forEach(termEl => {
          const termKeys = termEl.context.split('.').map(key => JSON.parse(key));
          termKeys.push(termEl.term);
          const lastKey = termKeys.length - 1;
          termKeys.reduce((val, el, idx) => {
            if (idx === lastKey) {
              val[el] = termEl.translation.content;
              return val;
            }
            if (!val[el]) {
              val[el] = {};
            }
            return val[el];
          }, languageData);
        });
        return languageData;
      },
    },
    fallbackLng: acceptedLanguages,
    supportedLngs: acceptedLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      lookupCookie: 'ServiceHubLng',
      caches: ['cookie', 'localStorage'],
      cookieMinutes: Number.MAX_VALUE,
      cookieDomain: suiteOrigin,
    },
    defaultNS: 'haworth-shopping-cart',
    ns: 'haworth-shopping-cart',
  });
}

export default i18n;
