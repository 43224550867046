import { useEffect, useState } from 'react';

function checkIsSigningRedirectParams() {
  const keys = ['code', 'scope', 'state', 'session_state'];

  const parts = window.location.hash.split('&');
  parts[0] = parts[0].replace('#', '');
  return parts.every(part => {
    const key = part.split('=')[0];
    return keys.some(k => k === key);
  });
}

export function useSigninRedirect(): boolean {
  const [isSignInRedirect, setIsSignInRedirect] = useState<boolean>(true);
  useEffect(() => {
    const signinRedirect = checkIsSigningRedirectParams();
    setIsSignInRedirect(signinRedirect);
  }, []);

  return isSignInRedirect;
}
