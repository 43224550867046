import React, { useEffect, useState } from 'react';
import { useDebounce } from '../shared/hooks/useDebounce';
import './ProductQuantity.scss';

interface ProductQuantityProps {
  value: number;
  setValue: Function;
  disabled: boolean;
}

const debounceInterval = 800;

const ProductQuantity = (props: ProductQuantityProps) => {
  const { value: actualValue, setValue: setActualValue } = props;
  const [value, setValue] = useState(actualValue);
  const { debouncedValue } = useDebounce(value, debounceInterval);
  const onChange = props.disabled ? () => {} : (e : React.ChangeEvent<HTMLInputElement>) => setValue(parseInt(e.target.value));
  useEffect(() => {
    if (!isNaN(debouncedValue) && actualValue !== debouncedValue) {
      if (debouncedValue < 1) {
        setActualValue(1);
      } else {
        setActualValue(debouncedValue);
      }
    }
  }, [debouncedValue, setValue, actualValue, setActualValue]);
  return (
    <input
      className="productquantity"
      type="number"
      min="1"
      value={value}
      onChange={onChange}
      disabled={props.disabled}
    ></input>
  );
};

export { ProductQuantity };
