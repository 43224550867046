import React from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryTotals } from '../summary/SummaryTotals';
import { CheckoutButton } from '../summary/CheckoutButton';
import { Totals } from '../../models';
import Notices from './Notices';
import './Summary.scss';

interface SummaryProps {
  orderId: string;
  totals: Totals;
  checkoutHandler: Function;
  punchoutHandler: (sessionReturnUrl: string) => void;
  inspectHandler: Function;
}

const Summary = (props: SummaryProps) => {
  const { t } = useTranslation();
  const { notices } = props.totals;
  return (
    <div className="summary">
      <div className="summary__header">
        <div className="summary__header__title">{t('summary.title')}</div>
      </div>
      <div className="summary__totals">
        <SummaryTotals totals={props.totals} />
      </div>
      <div className="summary__checkout">
        <CheckoutButton
          onCheckout={props.checkoutHandler}
          onPunchout={props.punchoutHandler}
          onInspect={props.inspectHandler}
        />
      </div>
      {notices.length > 0 && <div className="summary__notices"><Notices notices={notices} /></div>}
    </div>
  );
};

export { Summary };
