export enum PunchoutOperation {
  Create = 0,
  Edit = 1,
  Inspect = 2
}

export interface PunchoutDataResponse {
  punchOutSession: string;
  punchOutSessionReturnUrl: string;
  punchOutSessionRedirectUrl: string;
  punchOutOperationType: PunchoutOperation;
  canEditCartProducts: boolean;
  punchOutCartId: string;
}

export interface IsPunchoutResponse {
  isPunchout: boolean;
  data: PunchoutDataResponse;
}
