import React, { MouseEvent as ReactMouseEvent } from 'react';
import { Modal } from '../../shared';
import { useTranslation } from 'react-i18next';
import './ClearCartModal.scss';

interface ClearCartModalProps {
  isOpen: boolean;
  closeHandler: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  clearHandler: Function;
}

const ClearCartModal = (props: ClearCartModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={props.isOpen} closeHandler={props.closeHandler}>
      <div className="clearcart">
        <h3>{t('cart.clear_title')}</h3>
        <div className="modal__text">
          <div>{t('cart.clear_body')}</div>
          <div className="modal__button clearcart__confirm" onClick={() => props.clearHandler()}>
            {t('cart.clear_confirm')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ClearCartModal };


