import React from 'react';
import './ProductPrice.scss';
import { moneyFormatter } from '../../utils';

interface ProductPriceProps {
  currency: string;
  label?: string;
  value: number;
}

const ProductPrice = (props: ProductPriceProps) => {
  const formatValue = ({ value, currency }: ProductPriceProps) => {
    return moneyFormatter({ amount: value, currency });
  };
  return (
    <div className="product__info__item">
      {!!props.label && <span className="product__data__label">{props.label}</span>}
      <span>{formatValue(props)}</span>
    </div>
  );
};

export { ProductPrice };
