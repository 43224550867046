/* eslint-disable */
import { defineCustomElements, JSX as LocalJSX, applyPolyfills } from 'haworth-service-bar/loader';
import { HTMLAttributes } from 'react';

type StencilToReact<T> = {
  [P in keyof T]?: T[P] & Omit<HTMLAttributes<Element>, 'className'> & {
    class?: string;
    ref?:React.RefObject<unknown>;
  };
};

declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
    }
  }
}

applyPolyfills().then(() => {
  defineCustomElements(window);
});