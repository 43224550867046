import React from 'react';
import i18n from 'i18next';
import { useInjection } from '../../../../core/ioc';
import { IConfigurationServiceType, IConfigurationService } from '../../../../core/configuration';

export function ServiceFooter(): React.JSX.Element {
  const configurationService = useInjection<IConfigurationService>(IConfigurationServiceType);
  const config = configurationService.get();

  return <service-footer selected-language={i18n.language} settings-uri={config.serviceBar.serviceBarSettingsUri} />;
}
