import React from 'react';
import './Loader.scss';

interface LoaderProps {
  isButtonAnimation?: boolean;
  isReturningCart?: boolean;
  message?: string;
}

export function Loader(props: LoaderProps): React.JSX.Element {
  return (
    <div
      className={props.isButtonAnimation ? 'loader__button' : 'loader'}
    >
      <div
        className="spinner-border"
        style={{ width: !props.isButtonAnimation && '4.5rem', height: !props.isButtonAnimation && '4.5rem' }}
      >
        <span className="visually-hidden-focusable">{'Loading...'}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <h3>{props.isReturningCart ? props.message : ''}</h3>
      </div>
    </div>
  );
}
