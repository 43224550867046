import React from 'react';
import { RoleTypes } from './authentication/authentication.service';

interface Profile {
  email?: string;
  organization_catalog_legacy_mode?: string;
}

interface AppUserInfo {
  role: RoleTypes[];
  organization_id?: string;
  profile?: Profile;
  canEditProducts?: boolean;
}

const UserContext = React.createContext<AppUserInfo>({ role: [RoleTypes.DEFAULT] });

export { UserContext };
